.fc-button {
  .fc-icon {
    font-size: 1rem;
    vertical-align: middle;
    line-height: 0.9;
  }
}
.fc-button-primary {
  color: white;
  background-color: $primary;
  text-transform: capitalize;
  border-color: $primary;
}

.fc-button-primary:not(:disabled).fc-button-active {
  //   &::after {
  //     content: " ";
  //     position: absolute;
  //     left: 0;
  //     right: 0;
  //     top: 0;
  //     bottom: 0;
  //     background-color: rgba($color: #000000, $alpha: 0.17);
  //     z-index: -1;
  //   }
  //   background-color: $primary;
}
