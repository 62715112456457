.inbox-main-sidebar-container {
  padding: 15px;
  .inbox-intro {
  }
  .inbox-main-sidebar {
    width: 180px;
    overflow: hidden;
    .folders {
      height: calc(100vh - 215px);
      position: relative;
    }
    .inbox-main-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        a,
        span {
          position: relative;
          padding: 0.2rem 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $black;
          font-weight: 600;
          margin: 0.6rem 0;
          &.active {
            background-color: #d3edfd;
            border-radius: 0 0.3rem 0.3rem 0rem;
            font-weight: 900;
          /*  background-image: linear-gradient(to right, #00b4db, #00a7e1);
            color: white;*/
          }
          &:hover {
            /*color: $black;
            font-weight: 800;*/
            text-decoration: underline;
          }
          i {
            position: absolute;
            left:-26px;
            top: 16px;
          }
        }
      }
    }
  }

  .inbox-secondary-sidebar-container {
    border-radius: 10px;

    .inbox-secondary-sidebar-content {
      height: calc(100vh - 120px);
      overflow-y: auto;
      .inbox-topbar {
        height: 52px;
        display: flex;
        flex-direction: row;
      }
      .inbox-details {
        padding: 1rem 2rem;
      }
    }

    .inbox-secondary-sidebar-content-external {
      overflow-y: auto;
      .inbox-topbar {
        height: 52px;
        display: flex;
        flex-direction: row;
      }
      .inbox-details {
        padding: 1.5rem 2rem;
      }
    }
    .inbox-secondary-sidebar {
     /* border-radius: 10px;*/
      width: 360px;
      height: calc(100vh - 120px);
      overflow-y: scroll;
      border-right: 1px solid $gray-200;
      .mail-item {
        position: relative;
        display: flex;
        border-bottom: 1px solid $gray-200;
        padding: 1.25rem 1rem;
        cursor: pointer;
        &:hover {
          background: $gray-100;
        }
        
        .avatar {
          width: 15%;
        }
        .details {
         /* width: 60%;*/
        }
        .date {
          width: 25%;
          font-size: 10px;
          span {
            float: right;
          }
        }
        .select-icon {
          display: none;
        }
        &:hover .select-icon {
          display: flex;
          align-items: center;
        }
      }
      .avatar {
        img {
          margin: 4px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }
      .name {
        font-size: 12px;
        display: block;
        word-wrap: break-word;
      }
      .subject {
        font-size: 16px;
        display: block;
      }
      .active {
        background: #d3edfd;
        z-index: -1;
        &:hover {
          background: #d3edfd;
          z-index: -1;
        }
      }
      .unread-mail {
        font-weight: 900;
      }
    }
  }
}



@media (max-width: 767px) {
  .mail-item {
    padding: 1rem 0.5rem !important;
  }
  .inbox-secondary-sidebar {
    width: 280px !important;
  }
}

[dir="rtl"] {
  .inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a i {
    margin-right: 0;
    margin-left: 8px;
  }
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 1px;
  width: 160px;
  margin-left: 10px;
}



.react-tagsinput--focused {
  background-color: #fff !important;
  border-color: #86b7fe !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25)
}

.email-notValid {
  border-color: #f44336 !important;
}

.email-notValid .react-tagsinput--focused {
  background-color: #fff !important;
  border-color: #f44336 !important;
  box-shadow: 0 0 0 0.25rem rgba(244, 67, 54) !important;
}

.react-tagsinput-tag--focused {
  box-shadow: 0 0 0 0.25rem #a0a0a0;
}

.react-tagsinput {
  background-color: #f8f9fa;;
  border: 1px solid #ced4da;
  overflow: hidden;
  padding-left: 0px;
  padding-top: 2px;
  border-radius: 0.380rem;
  color: #47404f;
}

.react-tagsinput-tag {
  background-color: $secondary;
  border-radius: 6px;
  border: 1px solid $secondary;
  color: $white;
  display: inline-block;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 7px;
  padding: 3px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: $white;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.helper_text_email {
  background-color: $secondary;
  color: $white;
  margin-top: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
}

.default_suggestion {
  width: auto;
  border-radius: 10px;
  cursor: pointer;
}

.default_suggestion:hover {
  background-color: $primary;
  color: $white;
}

.ps__rail-y {
  z-index: 100;
}