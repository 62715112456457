/* Small devices like small phones */
@media only screen and (max-width: 767px) {
  .main-content-wrap{
    padding: 1rem 0rem 0;
  }
  .sec-side-content-wrapper{
    margin-left: 0;
  }
  .st-mobile-view{
    display: block;
  }
  .st-desktop-view{
    display: none;
  }

  .st-mail-sender-section {
    max-width: 65%;
  }

  .toggle-bar-continer{
    justify-content: space-between;
    padding-right: 0.9em;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  /* RULES TO HIDE/SHOW MAIL LIST AND MAIN CONTENT */
  .st-mlbx-sec-nav{
    width: 100% !important;
    display: block;
    left: 0px !important;

    .sidebar-close{
      display: none !important;
    }
  }
  .st-mlbx-message-opened{
    .st-mlbx-sec-nav{
      display: none !important;
    }
  }
  /* ----------------- */
  .topbar-toggle-btn{
    display: none;
  }
  .st-mail-date {
    min-width: 120px !important;
  }
}

@media only screen and (max-width: 370px) {
  .st-mail-date {
    min-width: 108px !important;
  }
}

@media only screen and (min-width: 768px) {
  .sec-side-content-wrapper{
    margin-left: 28em;;
  }
  .st-mobile-view{
    display: none;
  }
  .st-desktop-view{
    display: block;
  }



}

@media screen and (min-width: 1300px){
  .st-mlbx-main-nav{
    left: 0px !important;
  }

  .st-mlbx-sec-nav{
    left: 10.4rem !important;
  }
  .sec-side-content-wrapper {
    margin-left: 40.8em;
  }

  .st-compose-mobile{
    display: none;
  }
  .st-compose-default{
    display: block;
  }

  .toggle-bar-continer .menu-toggle{
    opacity: 0;
    width: 0px;
  }

}

@media screen and (max-width: 1300px){
  .st-compose-default{
    display: none;
  }
  .st-compose-mobile{
    display: block;
  }

  .inbox-main-sidebar-container .inbox-main-sidebar .folders {
    height: calc(100vh - 170px);
  }
}

// ----------------------------

@media (max-width: 500px){
  .st-compose-mobile{
    padding: 4px 2px 8px 11px !important;
    .st-btn-txt{
      display: none;
    }
  }
  .st-compose-mobile-view {
    font-size: 12px;
    display: grid !important;
    align-items: center;
    justify-content: center !important;
    width: 100%;
  }
  .st-compose-button-view {
    order: -1;
    margin-bottom: 8px;
  }
  .st-btn-compose{
    border: 0px !important;
    border-radius: 2rem;
    box-shadow: none !important;
    font-size: .9rem !important;
    padding-bottom: 10px !important;
    svg {
      margin-top: 2px !important;
    }
  }
  .suggestion__popup {
    box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.75) !important;
  }
  .st-mail-date {
    min-width: 108px;
  }
}


@media (max-width: 576px){
  .main-content-wrap {
    margin-top: 39px !important;
  }
  .st-mobile-custom-email-suggestion-table {
    .st-mobile-custom-email-role {
      display: none;
    }
    .st-mobile-custom-email-role-data {
      display: none ;
    } 
    .st-mobile-custom-email-role-display {
      display: block;
    }
  }
}


@media screen and (max-width: 999px){
}
@media screen and (min-width: 2079px) and (max-width: 2174px){
}
@media screen and (max-width: 480px) {
}
@media screen and (min-width: 1000px) and (max-width: 1406px) {
}
@media screen and (min-width: 1407px) and (max-width: 1502px) {

}
@media screen and (min-width: 1503px) and (max-width: 1598px) {

}
@media screen and (min-width: 1599px) and (max-width: 1694px) {

}
@media screen and (min-width: 1695px) and (max-width: 1790px) {

}
@media screen and (min-width: 1791px) and (max-width: 1886px) {

}
@media screen and (min-width: 1887px) and (max-width: 1982px) {

}
@media screen and (min-width: 1983px) and (max-width: 2078px) {

}
@media screen and (min-width: 2079px) and (max-width: 2174px) {

}
@media screen and (min-width: 2175px) and (max-width: 2270px) {

}
@media screen and (min-width: 2271px) and (max-width: 2366px) {

}
@media screen and (min-width: 2367px) and (max-width: 2462px) {

}
@media screen and (min-width: 2463px) and (max-width: 2558px) {

}
@media screen and (min-width: 2559px) {

}