.custom__tab.nav-tabs {
        .nav-item .nav-link {
            padding: 8px 12px;
            background-color: rgba(102, 51, 153, 0.1);
            border: 1px solid #dee2e6;
            &.active {
                border: 1px solid #dee2e6;
                background: #fff;
                border-bottom: none;
            }
        }
    }   
