body {
    --contexify-menu-bgColor: #fff;
    --contexify-separator-color: #eee;
    --contexify-item-color: #666;
    --contexify-activeItem-color: #3498db;
    --contexify-activeItem-bgColor: #e0eefd;
    --contexify-rightSlot-color: #6f6e77;
    --contexify-activeRightSlot-color: #3498db;
    --contexify-arrow-color: #6f6e77;
    --contexify-activeArrow-color: #fff;
    --contexify-menu-minWidth: 100px;
    --contexify-menu-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1)
}
