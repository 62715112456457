.toggle-bar-continer{

  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menu-toggle {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
    div {
      width: 24px;
      height: 1px;
      background: $foreground;
      margin: 3px 0;
    }
  }
}

.inbox-main-sidebar-container .inbox-main-sidebar{
  top: 3.9rem;
}


.main-content-wrap{
  padding-left: 0px !important;
  padding-right: 0px;
}

.text-styret-blue{
  color: #09b7f0;
}