@import "toggle-bar";
@import "custom-responsive";
@import "custom-context-menu";
@import "custom-tab";


$st-col-0: #2b2b2b;
$st-col-1:#00b4db;
$st-col-2:#00a7e1;
$st-col-3: #0d6efd;
$st-col-4: #d3edfd;
.mail-content-action-bar{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.st-mail-info{
  display: flex;
  justify-content: space-between;
}

.st-mail-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item{
  flex-direction: column;
}

.st-mail-sender{
  font-size: 0.9rem;
}

.st-mail-sender-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.st-mail-sender-role {
  color: $primary
}

.st-subject{
  font-size: 1rem;
}

.st-mlbx-main-nav{
  width: 240px;
  height: calc(100% - 4.4rem);
}

.st-btn-compose{
  background-image: linear-gradient(to right, $st-col-1, $st-col-2);
  color: white;
  font-size: 0.8rem;
  border-radius: 2rem;
  padding: 0.5rem 1.7rem 0.6rem 1.6rem;
  border: 1px solid #0095d9;
  -webkit-box-shadow: 0px 3px 5px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 5px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 5px -3px rgba(0,0,0,0.75);

  &.st-btn-mini{
    padding: 4px 18px 7px 13px;
  }
}


.st-col-0{ color: $st-col-0}
.st-col-1{ color: $st-col-1}
.st-col-2{ color: $st-col-2}

.st-nav-bottom{
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e4e2e2;
  padding-top: 0.1rem;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: transparent;
  background-image:linear-gradient(to right, $st-col-1, $st-col-2);
}


.st-header-wrapper{
  .st-header-info{
    width: calc(100% - 6rem);

    .st-header-com-email, .st-header-lang, .st-header-user{
      margin-right: 1rem;
    }

    .st-header-com-email{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap
    }
  }
  .st-header-logo{
    width: 6rem;
  }
}

.suggestion__popup {
  max-height: 275px;
  height: max-content;
  overflow-y: auto;
  tr:hover {
    td {
      background-color: $st-col-3;
      color: white;
    }
    .st-mail-sender-role {
      color: #fff;
    }
  }
}

.move__folder{
  cursor: pointer;
}
.move__folder:hover {
 /* color: $black;
  font-weight: 800;*/
  text-decoration: underline;
}

.move__folder__selected {
  background-color: #d3edfd;
  line-height: 2;
  border-radius: 0 0.3rem 0.3rem 0rem;
}

.move__folder__folderlist {
  height: 250px;
  position: relative;
  overflow-y: scroll;
}

.btn-primary {
  background-image: linear-gradient(to right, #00b4db, #00a7e1);
  color: white;
  font-size: 0.8rem;
  border: 1px solid #0095d9;
  box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.75)
}

.signature {
  p {
    margin: 0;
  }
}

.custom__hr {
  background-color: #333;
  border: 0 none;
  color: #333;
  height: 1px;
}

.st-mobile-custom-email-role-display {
  display: none;
}

.externalImageDownloadContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  background-color: #fffad6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 12px;
}

.text-underline {
  text-decoration: underline;
}

.st-mail-date {
  min-width: 103px;
}

.st-email-info-popup {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: start;

  .labels {
    display: grid;
    grid-template-rows: auto;
    justify-self: start;
  }

  .data {
    display: grid;
    grid-template-rows: auto;
    justify-self: start;
  }
}